
import { RouterLink } from 'vue-router';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('user', ['getLocale'])
  },
  watch: {
    getLocale() {
      this.bootIntercom();
    }
  },
  mounted() {
    this.bootIntercom();
  },
  methods: {
    bootIntercom() {
      /*this.$intercom.shutdown();
      this.$intercom.boot({
        language_override: this.getLocale
      });*/
    }
  }
};
